<template>
    <div class="cookies">
        <Header @logout="logout" v-if="user !== null"></Header>

        <div class="cookies-container">
            <h1>Cookie Policy</h1>

            <p class="content">
                Cookies are pieces of information saved to the computer's hard drive that track, save and store information about your interactions and usage of the website. This allows the website, through its server, to tailor its operations based on your needs, likes and dislikes by gathering and remembering information about your preferences.
            </p>

            <p class="content">
                You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting. If you wish to deny the use and saving of cookies from this website onto their computers hard drive, you should take necessary steps within your web browser’s security settings to block all cookies from this website and its external serving vendors. However, you must remember that declining cookies may impair certain functionalities of the website.
            </p>

            <p class="content">
                However, you must remember that declining cookies may impair certain functionalities of the website. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
            </p>
        </div>
        
        <Footer v-if="user !== null"></Footer>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    components: { 
        Header: () => import('@/components/utils/Header'),
        Footer: () => import('@/components/utils/Footer')
    },

    computed: {
        ...mapFields(['user'])
	},

    methods: {
        logout () {
            this.$store.dispatch('logout')
        }
    }
}
</script>

<style lang="scss" scoped>
.cookies {
    width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

    .cookies-container {
        height: 100vh;
        background-image: url('~@/assets/img/home_upperbg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 180px 100px;

        h1 {
            text-align: center;
            color: #221E1F;
            font-family: 'Crimson Pro', sans-serif;
            font-size: 30px;
            margin-bottom: 40px;
        }
    
        .content {
            max-width: 1156px;
            margin: 0 auto 15px;
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 15px;
            line-height: 26px;
        }
    }

}
</style>